import React from 'react';
import errorTriangle from 'images/icons/error-triangle.svg';
import journeyClosed from 'images/icons/journey-closed.svg';
import Container from './Container';
import styles from './ErrorMessage.module.scss';

const ErrorMessage = ({ children, title, type }) => (
  <Container>
    <div className={`${styles.errorScreen} ${type === 'CLOSED' ? styles.closed : ''}`}>
      {type === 'CLOSED' ? <img src={journeyClosed} alt="Closed icon" /> : <img src={errorTriangle} alt="Error icon" />}
      <h2>{title}</h2>
      <p>
        {children}
      </p>
    </div>
  </Container>
);

export default ErrorMessage;
