export const SET_SESSION = 'SET_SESSION';
export const SET_UID = 'SET_UID';
export const SET_MEMBERNO = 'SET_MEMBERNO';
export const SET_SESSIONID = 'SET_SESSIONID';
export const SET_NEWUSER = 'SET_NEWUSER';
export const SET_LOGGEDIN = 'SET_LOGGEDIN';
export const SET_CUSTOMERID = 'SET_CUSTOMERID';
export const SET_JWTTOKEN = 'SET_JWTTOKEN';
export const SET_LOGINID = 'SET_LOGINID';
export const SET_EFULFILMENTSHOWN = 'SET_EFULFILMENTSHOWN';
export const SET_TESCO_POPUP = 'SET_TESCO_POPUP';
export const SET_ORANGE_POINTS_POPUP = 'SET_ORANGE_POINTS_POPUP';
export const SET_REMINDER_ALERT = 'SET_REMINDER_ALERT';
export const SET_RENEW_NOW_BANNER = 'SET_RENEW_NOW_BANNER';
export const SET_RENEW_BANNER_IMAGE = 'SET_RENEW_BANNER_IMAGE';
export const SET_RENEWAL_DISCOUNT_SHOWN = 'SET_RENEWAL_DISCOUNT_SHOWN';
export const SET_BANNERHIDDEN = 'SET_BANNERHIDDEN';
export const SET_ERROR_DISMISSED = 'SET_ERROR_DISMISSED';
export const SET_TESCO_JID = 'SET_TESCO_JID';
export const SET_PRODUCT_UPGRADE = 'SET_PRODUCT_UPGRADE';
export const SET_ONETIME_EMAIL_DISABLED = 'SET_ONETIME_EMAIL_DISABLED';
export const SET_LOGIN_METHOD_USED = 'SET_LOGIN_METHOD_USED';

export const LOGIN_METHOD = {
  PASSWORD: 'PASSWORD',
  EMAIL_CODE: 'EMAIL_CODE',
  MOBILE_VERIFICATION: 'MOBILE_VERIFICATION',
  MAGIC_LINK: 'MAGIC_LINK',
};
