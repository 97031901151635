export const API_CUSTOMER = 'customer/{customerId}';
export const API_CUSTOMER_ADDRESS_RETRIEVE = 'customer/retrieveAddress/{addressId}';
export const API_CUSTOMER_ADDRESS_SEARCH = 'customer/findAddress/{postCode}';
export const API_CUSTOMER_COMMS = 'customer/{customerId}/communicationPreferences';
export const API_CUSTOMER_CONTACT_ADDRESS = 'customer/{customerId}/contact/address';
export const API_CUSTOMER_CONTACT_DOCUMENT_PREFS = 'customer/{customerId}/contact/paperless';
export const API_AUTORENEWAL_PREFS = 'policy/autoRenew';
export const API_POLICY_STATUS = 'policy/coverstatus';
export const API_POLICY_DETAILS = 'policy/coverdetails';
export const API_POLICY_RENEWAL_DETAILS = 'policy/renewaldetails';
export const API_CUSTOMER_CONTACT_EMAIL = 'customer/{membershipNumber}/contact/emailAddress';
export const API_CUSTOMER_CONTACT_PHONE = 'customer/{customerId}/contact/number';
export const API_CUSTOMER_DOB = 'customer/{customerId}/dateOfBirth';
export const API_CUSTOMER_FINDBYMEMBERSHIPID = (memberNo, uid) => `customer/findByMembershipId/${memberNo}?UID=${uid}&isNewUser=true`;
export const API_DOCUMENTS_LIST = 'document/{customerId}/policy/{policyId}/documents';
export const API_DOCUMENT = 'document/{customerId}/policy/{docType}/documents/{documentId}';
export const API_PASSWORD_UPDATE = 'user/password/reset';
export const API_PASSWORD_RESET = 'user/password/forgottenReset';
export const API_POLICY_FINDBYCUSTOMERID = 'policy/findByCustomerId/{customerId}';
export const API_POLICY_UPDATEADDITIONALMEMBERS = 'policy/{policyId}/additionalMembers';
export const API_FIND_VEHICLES = 'vehicle/details';
export const API_SAVE_VEHICLE = 'vehicles/{userId}/vehicle';
export const API_USER_VALIDATE = 'user/{membershipNo}/validate';
export const API_USER_MAGICLINK_CREATE = 'user/magiclink/create';
export const API_EMAIL_AVAILABLE = 'user/email/available';
export const API_USER_EMAIL = 'user/{membershipNo}/contact/emailAddress';
export const API_VEHICLES_REMINDERS = 'vehicles/{userId}';
export const API_UPDATE_VEHICLES_REMINDERS = 'vehicles/{userId}/vehicle/{registration}/reminders';
export const API_VEHICLE_BY_REGISTRATION = 'vehicles/{userId}/vehicle/{registration}';
export const API_MOT_INFO = 'vehicle/mot/{registration}';
export const API_MOT_HISTORY = 'vehicle/mot/{registration}/history';
export const API_MEMBERSHIP_OVERVIEW_GRID_LAYOUT_MOBILE = 'customer/{customerId}/membership/{membershipId}/overview/mobile';
export const API_MEMBERSHIP_OVERVIEW_GRID_LAYOUT = 'customer/{customerId}/membership/{membershipId}/overview';
export const API_DOCUMENT_GRID_LAYOUT = 'customer/{customerId}/membership/{membershipId}/documents';
export const API_STATIC_CONTENT = 'cms/staticcontent/{contentTypeKey}';
/* Tesco */
export const API_CUSTOMER_JID_TESCO_LOGIN = 'tesco/authenticate/{customerId}';
export const TESCO_LOGIN_REDIRECT_URL_RENEWAL = '/uk-breakdown-cover/tesco-renewal';
export const TESCO_LOGIN_REDIRECT_URL_BREAKDOWN = '/breakdown-cover/uk-breakdown?JID=';
/* Tactial Upgrade */
export const API_TACTICAL_UPGRADE_DATA = 'policy/productUpgrade/{customerId}/{productName}';
export const API_PRODUCT_UPGRADE_CONFIRMATION = 'policy/policyProductUpgrade';
export const API_PRODUCT_CREATE_PAYMENT_ID = 'policy/policyProductUpgrade/payment';
export const API_PRODUCT_PAYMENT_STATUS = (paymentId) => `policy/policyProductUpgrade/payment/${paymentId}`;
export const API_MOT_PLUS = 'policy/{customerId}/motplus/{userId}';
export const API_PRODUCT_UPGRADES = 'product/upgrades';
export const API_PRODUCTS_INCLUDED = 'products/included';
/* Renewal */
export const API_RENEWAL_DATA = 'policy/renewal/{customerId}';
export const API_RENEWAL_CREATE_PAYMENT_ID = 'policy/renewal/createwithpayment';
export const API_RENEWAL_PAYMENT_STATUS = 'policy/renewal/update';
export const API_RENEWAL_DIRECT_DEBIT_STATUS = 'policy/renewal/directdebit/validate';
export const API_RENEWAL_DIRECT_VALIDATE_ONLY = 'policy/renewal/directdebit/validatebankdetails';
export const API_RENEWAL_RELEASE_LOCK = 'policy/renewal/releaselock';
export const API_RENEWAL_POPUP = 'policy/renewal/popUpImage/{customerId}';
export const API_RENEWAL_ELIGIBILITY = 'policy/renewal/eligibility';
/* Payment Service */
export const API_GET_PAYMENT_IFRAME = (paymentId) => `${process.env.REACT_APP_PAYMENT_SERVICE_HOST}?paymentId=${paymentId}`;
/* SMR */
export const API_SMR_CONTENT = 'smr/content';
/* Orange Points */
export const API_ORANGE_POINTS_REWARDS = 'policy/renewal/getOrangePointsRewards';
export const API_ORANGE_POINTS_CLAIM_REWARD = 'policy/renewal/claimOrangePointsReward';
