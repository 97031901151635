import * as SessionConstants from 'constants/sessionOptionsConstants';

export function setSessionInfo(sessionData) {
  return {
    type: SessionConstants.SET_SESSION,
    payload: sessionData,
  };
}

export function setUid(uid) {
  return {
    type: SessionConstants.SET_UID,
    payload: uid,
  };
}

export function setMemberNo(memberNo) {
  return {
    type: SessionConstants.SET_MEMBERNO,
    payload: memberNo,
  };
}

export function setSessionId(sessionId) {
  return {
    type: SessionConstants.SET_SESSIONID,
    payload: sessionId,
  };
}

export function setNewUser(newUser) {
  return {
    type: SessionConstants.SET_NEWUSER,
    payload: newUser,
  };
}

export function setLoggedIn(loggedIn) {
  return {
    type: SessionConstants.SET_LOGGEDIN,
    payload: loggedIn,
  };
}

export function setCustomerId(customerId) {
  return {
    type: SessionConstants.SET_CUSTOMERID,
    payload: customerId,
  };
}

export function setJwtToken(jwtToken) {
  return {
    type: SessionConstants.SET_JWTTOKEN,
    payload: jwtToken,
  };
}

export function setLoginID(loginId) {
  return {
    type: SessionConstants.SET_LOGINID,
    payload: loginId,
  };
}

export function setEfulfilmentShown(eFulfilmentShown) {
  return {
    type: SessionConstants.SET_EFULFILMENTSHOWN,
    payload: eFulfilmentShown,
  };
}

export function setTescoPopupShown(tescoPopupShown) {
  return {
    type: SessionConstants.SET_TESCO_POPUP,
    payload: tescoPopupShown,
  };
}

export function setOrangePointsShown(orangePointsShown) {
  return {
    type: SessionConstants.SET_ORANGE_POINTS_POPUP,
    payload: orangePointsShown,
  };
}

export function setReminderAlertShown(reminderAlertShown) {
  return {
    type: SessionConstants.SET_REMINDER_ALERT,
    payload: reminderAlertShown,
  };
}

export function setRenewBannerShown(renewBannerShown) {
  return {
    type: SessionConstants.SET_RENEW_NOW_BANNER,
    payload: renewBannerShown,
  };
}

export function setRenewBannerImgUrl(renewBannerImgUrl) {
  return {
    type: SessionConstants.SET_RENEW_BANNER_IMAGE,
    payload: renewBannerImgUrl,
  };
}

export function setRenewalDiscountShown(discountRenewal) {
  return {
    type: SessionConstants.SET_RENEWAL_DISCOUNT_SHOWN,
    payload: discountRenewal,
  };
}

export function setBannerHidden(bannerHidden) {
  return {
    type: SessionConstants.SET_BANNERHIDDEN,
    payload: bannerHidden,
  };
}

export function errorDismissed() {
  return {
    type: SessionConstants.SET_ERROR_DISMISSED,
    payload: true,
  };
}

export function setTescoJID(jidTesco) {
  return {
    type: SessionConstants.SET_TESCO_JID,
    payload: jidTesco,
  };
}

export function setSelectedProductUpgrade(upgradedProduct) {
  return {
    type: SessionConstants.SET_PRODUCT_UPGRADE,
    payload: upgradedProduct,
  };
}

export function setOneTimeEmailLoginDisabled(oneTimeEmailDisabled) {
  return {
    type: SessionConstants.SET_ONETIME_EMAIL_DISABLED,
    payload: oneTimeEmailDisabled,
  };
}

export function setLoginMethodUsed(method) {
  return {
    type: SessionConstants.SET_LOGIN_METHOD_USED,
    payload: method,
  };
}
