import { useEffect } from 'react';
import useViewport from 'utils/useViewportHelper';

const useConfetti = () => {
  const { isMobile } = useViewport();

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'confetti';
    script.src = 'https://cdn.jsdelivr.net/npm/js-confetti@latest/dist/js-confetti.browser.js';
    script.crossorigin = true;
    script.addEventListener('load', async () => {
      // eslint-disable-next-line no-undef
      const jsConfetti = new JSConfetti();
      const confettiOptions = {
        confettiNumber: 500,
        emojiSize: isMobile ? 18 : 10,
        emojis: ['🟠', '⚪'],
      };
      await jsConfetti.addConfetti(confettiOptions);
    });
    document.body.appendChild(script);
  }, []);
};

export default useConfetti;
