import React, { Component } from 'react';
import { GetContent, ResponseCode, mapURL } from 'services/ContentService';
import * as DefaultText from 'configuration/defaultText';
import RenderLink from 'components/RenderLink';
import styles from './Footer.module.scss';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageModel: false,
    };
  }

  async componentDidMount() {
    const { pageModel } = this.state;
    if (!pageModel) {
      const pageData = await GetContent(
        'footer',
        null,
        ['footeritems', 'sociallinks', 'columns.items'],
      );
      this.processPageModelContent(pageData);
    }
  }

  renderStandardPanel = (data) => data.map((column) => (
    <div key={column.title} className="col-6 col-sm-6 col-md-4">
      <div className="footerLinks">
        <h5>{column.title}</h5>
        <ul>
          {column.links.map((link) => (
            <li key={link.url + link.text}>
              <RenderLink link={link} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  ))

  renderNewsletterPanel = (data) => (
    <div className="col-sm-6 col-md-12">
      <div className="spacer-40 d-block d-sm-none" />
      <div className="footerSignUp">
        <h5>{data.title}</h5>
        <p>{data.text}</p>
        <RenderLink
          link={data.link}
          className="btn btn-primary"
        >
          Subscribe
        </RenderLink>
      </div>
      <div className="spacer-20 hidden-sm" />
    </div>
  )

  renderSocialMediaPanel = (data) => (
    <div className="col-sm-6 col-md-12">
      <div className="socialLinks">
        <h5>{data.title}</h5>
        <ul className="list-inline">
          {data.links.map((item) => (
            <li key={item.href + item.text} className="list-inline-item">
              <RenderLink
                link={item}
                className={`fab fa-${item.cssClass}`}
              >
                <span className="d-none">{item.text}</span>
              </RenderLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )

  renderPanels = (data) => (
    <div className="row">
      {this.renderStandardPanel(data.panels)}
      <div className="col-12 col-sm-12 col-md-4">
        <div className="row">
          {this.renderNewsletterPanel(data.newsletterPanel)}
          {this.renderSocialMediaPanel(data.socialLinksPanel)}
        </div>
      </div>
    </div>
  )

  renderSiteFooter = (ribbon) => (
    <div className="siteFooterRibbon">
      <div className="container">
        <div className="row small">
          <div className="col-sm-8">
            <div className="footerLinks">
              <ul>
                {ribbon?.items.map((link, key) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={key}>
                    <RenderLink link={link} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-12 col-sm-4 text-right">
            {`© ${new Date().getFullYear()} ${ribbon?.copyright}`}
          </div>
        </div>
      </div>
    </div>
  )

  processPageModelContent = (result) => {
    if (result.responseCode === ResponseCode.ERROR) {
      return this.setState({ pageModel: { error: DefaultText.APIError } });
    }

    const data = result.payload;
    const pageModel = {
      pageModel: {
        panels: data.columns.map((column) => ({
          title: column.title,
          links: column.items.map((item) => mapURL(item)),
        })),
        newsletterPanel: {
          title: data.newslettertitle,
          text: data.newslettertext,
          link: mapURL(data.newsletterurllink),
        },
        socialLinksPanel: {
          title: data.socialtitle,
          links: data.sociallinks.map((item) => mapURL(item)),
        },
        callcharges: data.callchargestext,
        ribbon: {
          copyright: data.copyrightwording,
          items: data.footeritems.map((item) => mapURL(item)),
        },
      },
    };
    this.setState(pageModel);
    return undefined;
  }

  render() {
    const { pageModel } = this.state;
    if (pageModel.error) { return pageModel.error; }

    return (
      <footer className="navbar-fixed-bottom">
        <div className="siteFooter">
          <div className="container">
            <div><a className={styles.termsAndConditionsLink} href="/myrac/terms-conditions">myRAC Terms and Conditions</a></div>
            {pageModel && (this.renderPanels(pageModel))}
            <div className="row callcharges">
              <div className="col-12">
                <small>{pageModel?.callcharges}</small>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-4">
                <h5>Who regulates us?</h5>
                <p>Your cover is arranged and administered by RAC Financial Services Limited which is authorised and regulated by the Financial Conduct Authority. Their FCA number is 313989.</p>
                <p>Your cover is provided by RAC Insurance Limited which is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. Their FCA number is 202737.</p>
                <p>You can check this information on the Financial Services Register <a href="https://register.fca.org.uk/s/" target="_blank" rel="noreferrer">here</a>.</p>
              </div>
            </div>
          </div>
        </div>
        {this.renderSiteFooter(pageModel?.ribbon)}
      </footer>
    );
  }
}

export default Footer;
