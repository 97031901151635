import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import trackEvent from 'services/AnalyticsService';
import orangePointsImage from 'assets/images/orangepoints/splash.gif';
import { ORANGE_POINTS_JOURNEY } from 'constants/routeConstants';
import Button from 'shared/components/Button/Button';

const OrangePointsModal = (props) => {
  const { action, isMobile } = props;

  const navigate = useNavigate();

  const banner = {
    backgroundImage: `url(${orangePointsImage})`,
    backgroundPositionY: 'center',
    backgroundColor: '#f95108',
    backgroundRepeat: 'no-repeat',
    backgroundSize: isMobile ? 'contain' : 'cover',
  };

  const button = {
    color: '#f95108',
    backgroundColor: '#fff',
  };

  useEffect(() => {
    trackEvent('MyRAC_OrangePoints_Pop_Up_Displayed');
  }, []);

  return (
    <>
      <div className="modal myrac Modal__overflow" role="dialog">
        <div className="modal-dialog bg-orange">
          <div className="modal-content white" style={banner}>
            <div className="modal-header float-right border-0">
              <button
                title="Close"
                type="button"
                className="close ascii-cross btn-close btn-close-white fs-5 opacity-100 p-3"
                aria-hidden="true"
                onClick={() => {
                  trackEvent('MyRAC_OrangePoints_Pop_Up_Closed');
                  action();
                }}
              />
            </div>
            <div className="modal-body text-center p-1">
              <div id="spacer" style={{ height: isMobile ? '50vh' : '400px' }} />
              <h3>The UK&apos;s first and only breakdown loyalty points programme</h3>
              <p>Exchange your points for discounts that suit you.</p>
            </div>
            <div className="px-3 mb-4">
              <Button
                fullWidth
                onClickHandler={() => {
                  trackEvent('MyRAC_OrangePoints_Pop_Up_CTA_Clicked');
                  navigate(ORANGE_POINTS_JOURNEY);
                }}
                buttonText="Spend your points"
                buttonStyles={button}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in Modal__block" />
    </>
  );
};

export default OrangePointsModal;
