import * as SessionConstants from 'constants/sessionOptionsConstants';

// eslint-disable-next-line default-param-last
const sessionReducer = (state = {
  uid: '',
  memberNo: '',
  jwtToken: '',
  sessionId: '',
  isLoggedIn: false,
  customerId: '',
  loginId: '',
  isTescoLogin: '',
  tescoAffinity: '',
  efulFilmentShown: false,
  errorDismissed: undefined,
  bannerHidden: {},
  jidTesco: undefined,
  upgradedProduct: undefined,
  discountRenewal: false,
  oneTimeEmailDisabled: false,
  loginMethodUsed: null,
}, action) => {
  switch (action.type) {
    case SessionConstants.SET_SESSION:
      return {
        ...state,
        uid: action.payload.uid,
        memberNo: action.payload.memberNo,
        customerId: action.payload.customerId,
        isMigratedUser: action.payload.isMigratedUser,
        jwtToken: action.payload.jwtToken,
        sessionId: action.payload.sessionId,
        isLoggedIn: action.payload.isLoggedIn,
        loginId: action.payload.loginId,
        isTescoLogin: action.payload.isTescoLogin,
        tescoAffinity: action.payload.tescoAffinity,
        efulFilmentShown: action.payload.efulFilmentShown,
        bannerHidden: action.payload.bannerHidden,
        jidTesco: action.payload.jidTesco,
        upgradedProduct: action.payload.upgradedProduct,
        discountRenewal: action.payload.discountRenewal,
        oneTimeEmailDisabled: action.payload.oneTimeEmailDisabled,
      };
    case SessionConstants.SET_UID:
      return {
        ...state,
        uid: action.payload,
      };
    case SessionConstants.SET_MEMBERNO:
      return {
        ...state,
        memberNo: action.payload,
      };
    case SessionConstants.SET_SESSIONID:
      return {
        ...state,
        sessionId: action.payload,
      };
    case SessionConstants.SET_LOGGEDIN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case SessionConstants.SET_CUSTOMERID:
      return {
        ...state,
        customerId: action.payload,
      };
    case SessionConstants.SET_JWTTOKEN:
      return {
        ...state,
        jwtToken: action.payload,
      };
    case SessionConstants.SET_LOGINID:
      return {
        ...state,
        loginId: action.payload,
      };
    case SessionConstants.SET_EFULFILMENTSHOWN:
      return {
        ...state,
        efulFilmentShown: action.payload,
      };
    case SessionConstants.SET_TESCO_POPUP:
      return {
        ...state,
        tescoPopupShown: action.payload,
      };
    case SessionConstants.SET_ORANGE_POINTS_POPUP:
      return {
        ...state,
        orangePointsShown: action.payload,
      };
    case SessionConstants.SET_REMINDER_ALERT:
      return {
        ...state,
        reminderAlertShown: action.payload,
      };
    case SessionConstants.SET_RENEW_NOW_BANNER:
      return {
        ...state,
        renewBannerShown: action.payload,
      };
    case SessionConstants.SET_RENEW_BANNER_IMAGE:
      return {
        ...state,
        renewBannerImgUrl: action.payload,
      };
    case SessionConstants.SET_BANNERHIDDEN:
      return {
        ...state,
        bannerHidden: { ...state.bannerHidden, ...action.payload },
      };
    case SessionConstants.SET_ERROR_DISMISSED:
      return {
        ...state,
        errorDismissed: action.payload,
      };
    case SessionConstants.SET_TESCO_JID:
      return {
        ...state,
        jidTesco: action.payload,
      };
    case SessionConstants.SET_PRODUCT_UPGRADE:
      return {
        ...state,
        upgradedProduct: { ...state.upgradedProduct, ...action.payload },
      };
    case SessionConstants.SET_RENEWAL_DISCOUNT_SHOWN:
      return {
        ...state,
        discountRenewal: action.payload,
      };
    case SessionConstants.SET_ONETIME_EMAIL_DISABLED:
      return {
        ...state,
        oneTimeEmailDisabled: action.payload,
      };
    case SessionConstants.SET_LOGIN_METHOD_USED:
      return {
        ...state,
        loginMethodUsed: action.payload,
      };
    default:
      return state;
  }
};

export default sessionReducer;
