import React from 'react';
import infoWhiteSvg from 'assets/images/icons/info-full-white.svg';
import banner1000Mobile from './assets/1000-points-banner-mobile.gif';
import banner1000Desktop from './assets/1000-points-banner-desktop.gif';
import banner1500Mobile from './assets/1500-points-banner-mobile.gif';
import banner1500Desktop from './assets/1500-points-banner-desktop.gif';
import banner2000Mobile from './assets/2000-points-banner-mobile.gif';
import banner2000Desktop from './assets/2000-points-banner-desktop.gif';
import confirmationBannerMobile from './assets/confirmation-banner-mobile.gif';
import confirmationBannerDesktop from './assets/confirmation-banner-desktop.gif';
import styles from './Banner.module.scss';

const Banner = ({ orangePointsAmount = 0 }) => {
  /* Display different banner for different amount of Orange Points */
  let bannerToShowOnMobile = banner1000Mobile;
  let bannerToShowOnDesktop = banner1000Desktop;

  if (orangePointsAmount >= 1500) {
    bannerToShowOnMobile = banner1500Mobile;
    bannerToShowOnDesktop = banner1500Desktop;
  }
  if (orangePointsAmount >= 2000) {
    bannerToShowOnMobile = banner2000Mobile;
    bannerToShowOnDesktop = banner2000Desktop;
  }

  return (
    /* If no orangePointsAmount is passed to this component it will display confirmation screen banner  */
    orangePointsAmount > 0 ? (
      <div className={styles.bannerWrapper}>
        <img className={styles.banner} src={bannerToShowOnMobile} alt={`You've got ${orangePointsAmount} orange points!`} />
        <img className={styles.bannerDesktop} src={bannerToShowOnDesktop} alt={`You've got ${orangePointsAmount} orange points!`} />
        <p>
          With the RAC, loyalty pays off.<br />
          Earn Orange Points every year you&apos;re with us, and spend them on savings that suit you.<br />
          <a href="#termsAndConditions" className={styles.bannerLink}>
            <img width="18px" height="18px" src={infoWhiteSvg} alt="White information icon" />
            T&Cs
          </a>
        </p>
      </div>
    ) : (
      <div className={styles.bannerWrapper}>
        <img className={styles.banner} src={confirmationBannerMobile} alt="Orange Points banner" />
        <img className={styles.bannerDesktop} src={confirmationBannerDesktop} alt="Orange Points banner" />
      </div>
    )
  );
};

export default Banner;
