import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROOT } from 'constants/routeConstants';
import closeWebView from 'utils/reactNativeWebView';
import useConfetti from 'hooks/useConfetti';
import useReferrerParam from 'hooks/useReferrerParam';
import Banner from './Banner';
import styles from './ConfirmationScreen.module.scss';

const ConfirmationScreen = ({ response }) => {
  const navigate = useNavigate();
  const discount = response.memberChoseRenewalDiscount ? response.renewalDiscountOfferAmount : response.smrDiscountOfferAmount;
  const isAppView = useReferrerParam();

  useConfetti();

  const finishBtnHandler = () => {
    if (isAppView) {
      closeWebView('ORANGE_POINTS_REDEEMED_CLOSE');
    } else {
      navigate(ROOT, { state: { isOrangePointsRedeemed: true, memberChoseRenewalDiscount: response.memberChoseRenewalDiscount } });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // If user is accessing journey through app, the app will be informed Orange Points were claimed
    if (isAppView) {
      closeWebView('ORANGE_POINTS_REDEEMED');
    }
  }, []);

  return (
    <div className={`${styles.confirmationScreenWrapper} ${isAppView ? styles.isAppView : ''}`}>
      <Banner />
      <div className={styles.info}>
        <h2><b>Nice!</b>You&apos;ve just saved  £{discount}</h2>
        {response.memberChoseRenewalDiscount ? (
          <p>
            We&apos;ve taken £{response.renewalDiscountOfferAmount} off the price of your cover. We&apos;ll update your renewal docs
            in myRAC and send them by email in the next 24 hours.
            <br /><br />
            Make sure to check through your documents before your renewal date - then, relax!
            You don&apos;t have to do anything else. You&apos;re covered.
          </p>
        ) : (
          <p>
            Once your cover renews we&apos;ll email you a unique booking link
            for your discounted car service with RAC Mobile Mechanics.
            <br /><br />
            Make sure to check through your documents before your renewal date - then, relax!
            You don&apos;t have to do anything else. You&apos;re covered.
          </p>
        )}
      </div>
      <button type="button" className={styles.btn} onClick={finishBtnHandler}>Finish</button>
    </div>
  );
};

export default ConfirmationScreen;
